import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS, textBase, textSM } from '@styles/fonts'
import styled, { css } from 'styled-components'

export const ProjectFeatures = styled.div`
  ${textSM};
  letter-spacing: 1px;
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};
  margin-bottom: 24px;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  > span {
    margin-right: 26px;
  }

  @media ${DEVICES.mobile} {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 28px;

    > span {
      margin-bottom: 8px;
      margin-right: 0;
    }
  }

  ${({ gallery }) =>
    gallery &&
    css`
      flex-direction: column;
      align-items: flex-start;

      > span {
        margin-bottom: 8px;
        margin-right: 0;
      }
    `}
`

export const KeyDetail = styled.div`
  ${textBase};
  display: inline-flex;
  align-items: center;
  margin-right: 22px;
  white-space: nowrap;
  color: ${COLORS.secondary};

  svg {
    margin-left: 8px;
  }
`
