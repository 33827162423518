import styled from 'styled-components'
import { DEVICES } from '@constants/global'
import { FONTS, textSM } from '@styles/fonts'
import { COLORS } from '@styles/colors'

export const ProjectInfo = styled.div`
  padding-bottom: 20px;
  margin-bottom: 56px;
  border-bottom: 2px solid ${COLORS.sand};

  @media ${DEVICES.mobile} {
    margin-bottom: 40px;
  }
`

export const ProjectInfoList = styled.div`
  margin-bottom: -4px;
`

export const ProjectInfoHeading = styled.h4`
  ${textSM};
  letter-spacing: 1px;
  margin-bottom: 20px;
  color: ${COLORS.secondary};
  text-transform: uppercase;
`

export const ProjectInfoGroup = styled.div`
  color: ${COLORS.navi};
  margin-bottom: 4px;

  a {
    color: ${COLORS.navi};
    text-decoration: underline;

    &:hover {
      color: ${COLORS.primary};
    }
  }
`

export const ProjectInfoLabel = styled.span`
  font-family: ${FONTS.medium};
`

export const ProjectInfoValue = styled.span``
