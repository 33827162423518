import { UrlBuilder } from '../helpers'
import { apiCall } from '../apiServerHelper'
import { apis } from '../apiUrls'

export const getProjects = async params => {
  let url = apis.PROJECTS
  if (params) url = UrlBuilder(url, params)
  return await apiCall(url)
}

export const getProjectDetails = async slug => {
  return await apiCall(`${apis.PROJECTS}${slug}/`)
}
