import StatementOfInformation from '@components/PropertyDetails/StatementOfInformation'
import ProjectFeatures from '../ProjectFeatures'
import * as S from './ProjectSummary.styled'

const ProjectSummary = ({ documents, propertyType, features }) => {
  return (
    <S.ProjectSummary>
      <ProjectFeatures features={features} propertyType={propertyType} />
      <StatementOfInformation documents={documents} />
    </S.ProjectSummary>
  )
}

export default ProjectSummary
