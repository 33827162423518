import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS } from '@styles/fonts'
import styled from 'styled-components'

export const BlockInfoTitle = styled.h2`
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.1px;
  margin-bottom: 40px;
  text-transform: none;
`

export const BlockInfo = styled.div`
  @media ${DEVICES.mobile} {
    ${BlockInfoTitle} {
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 0.1px;
      margin-bottom: 32px;
    }
  }
`
