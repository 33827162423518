import styled from 'styled-components'
import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS } from '@styles/fonts'

export const ProjectAddress = styled.h1`
  font-size: 36px;
  line-height: 44px;
  color: ${COLORS.secondary};
  margin-bottom: 10px;
  letter-spacing: 2.4px;
  font-family: ${FONTS.regular};
  margin-bottom: 26px;

  strong {
    font-family: ${FONTS.bold};
  }

  @media ${DEVICES.mobile} {
    margin-bottom: 22px;
    line-height: 30px;
    font-size: 22px;
    letter-spacing: 1.6px;
    margin-bottom: 18px;
  }
`
