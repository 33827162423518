import styled from 'styled-components'
import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS } from '@styles/fonts'

export const ProjectDetails = styled.div`
  padding-right: 86px;

  @media ${DEVICES.tabletMini} {
    padding-right: 0;
  }
`

export const ProjectEnquiryDes = styled.span`
  color: ${COLORS.primary};
  font-family: ${FONTS.regular} !important;
  text-transform: none !important;

  strong {
    text-transform: uppercase;
  }
`
