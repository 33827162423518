import IconBedBig from '@icons/BedBig'
import IconBathBig from '@icons/BathBig'
import IconCarBig from '@icons/CarBig'
import * as S from './ProjectFeatures.styled'

const ICONS = {
  beds: <IconBedBig />,
  baths: <IconBathBig />,
  carparks: <IconCarBig />,
}

const ProjectFeatures = ({ features, propertyType, gallery }) => {
  return (
    <S.ProjectFeatures gallery={gallery}>
      <span>{propertyType}</span>
      <div>
        {features?.map(
          (feature, key) =>
            feature.label && (
              <S.KeyDetail key={`feature-${key}`}>
                <span>{feature.label}</span>
                {ICONS[feature.icon]}
              </S.KeyDetail>
            )
        )}
      </div>
    </S.ProjectFeatures>
  )
}

export default ProjectFeatures
