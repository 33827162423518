import { NextSeo } from 'next-seo'
import Breadcrumbs from '@global/Breadcrumbs'
import { Col, Container, Row, Section } from '@styles/global.styled'
import ContactNav from '@components/PropertyDetails/ContactNav'
import ProjectAddress from '@components/Projects/ProjectDetails/ProjectAddress'
import HelpfulLinks from '@components/PropertyDetails/HelpfulLinks'
import dynamic from 'next/dynamic'
import ProjectInfo from './ProjectInfo'
import BlockInfo from '@global/BlockInfo'
import ProjectSummary from './ProjectSummary'
import ProjectHero from './ProjectHero'
import { titleCase } from '@lib/helpers'
import { useMemo } from 'react'
import { useSiteSettingsContext } from '@context/SiteSettingsContext'
import WagtailBlocksSection from '@global/Blocks/WagtailBlocksSection'
import ListingPreview from '@global/Blocks/ListingPreview'
import * as S from './ProjectDetails.styled'

const EnquiryForm = dynamic(() => import('@global/Form/EnquiryForm'), {
  ssr: false,
})

const Map = dynamic(() => import('@global/Map/SimpleMap'), {
  ssr: false,
})

const ProjectDetails = ({ project, properties, suburbData, location }) => {
  const settingContext = useSiteSettingsContext()

  const {
    documents,
    address_streetNumber,
    address_street,
    address_suburb,
    address_state,
    address_postcode,
  } = project

  const projectInfo = project.sidebar_content.find(
    (it) => it.type === 'sidebar_items'
  )

  const helpfulLinks = useMemo(() => {
    const links = documents
      ?.filter(
        (it) =>
          it.name?.toLowerCase() !== 'statement of information' &&
          it.name?.toLowerCase() !== 'statementofinformation'
      )
      ?.map((it) => ({
        label: it.name,
        type: 'document',
        link: it.file,
        target: '_self',
      }))

    if (
      settingContext?.settings?.contentsettings?.show_resources_button &&
      settingContext?.settings?.contentsettings?.resources_button_text &&
      settingContext?.settings?.contentsettings?.resources_url
    ) {
      links.push({
        label: settingContext.settings.contentsettings.resources_button_text,
        type: 'link',
        link: settingContext.settings.contentsettings.resources_url,
      })
    }

    return links
  }, [
    documents,
    settingContext?.settings?.contentsettings?.show_resources_button,
  ])

  const address = (
    <span>
      {address_streetNumber} {address_street}
      <br />
      <strong>{address_suburb}</strong> {address_state} {address_postcode}
    </span>
  )

  const projectImage = project.images?.[0].image

  const shareInfo = {
    url: project.url,
    quote: `${address_streetNumber} ${address_street}, ${address_suburb} ${address_state} ${address_postcode}`,
    media: projectImage,
    image: projectImage,
    subject: 'Check out this project from Barry Plant',
  }

  const features = [
    {
      label: project.beds,
      icon: 'beds',
    },
    {
      label: project.baths,
      icon: 'baths',
    },
    {
      label: project.carparks,
      icon: 'carparks',
    },
  ]

  return (
    <>
      <Container>
        <NextSeo title={project.title} />
        <Breadcrumbs
          breadcrumbs={[
            { label: 'For Sale', url: '#' },
            { label: 'Projects', url: '/projects-search' },
            { label: project.title, url: '#' },
          ]}
        />
        <ProjectHero
          images={project.images}
          address={address}
          shareInfo={shareInfo}
          projectType={project.project_type}
          features={features}
          propertyType={project.property_type}
        />

        <Row>
          <Col lg={8}>
            <S.ProjectDetails>
              <ProjectAddress address={address} />
              <ProjectSummary
                propertyType={project.property_type}
                documents={documents}
                features={features}
              />
              <ContactNav
                project
                agents={project.agents}
                office={project.offices?.[0]}
                mobile
              />

              <WagtailBlocksSection
                blocks={project.content_blocks}
                paddingSmall
              />

              <BlockInfo title={project.headline} content={project.content} />
              <ProjectInfo items={projectInfo?.value} />
              {location && <Map coordinates={location} />}
              <HelpfulLinks helpfulLinks={helpfulLinks} />
            </S.ProjectDetails>
          </Col>
          <Col lg={4}>
            <ContactNav
              project
              agents={project.agents}
              office={project.offices?.[0]}
            />
          </Col>
        </Row>
      </Container>

      {properties.results.length > 0 && (
        <Section withPaddingBottom>
          <ListingPreview
            title='Listings for this project'
            items={{ properties: properties.results }}
            showSelectOptions={false}
            globalViewMore={
              properties.count > 9
                ? {
                    url: `/project-properties?parent_project_id=${project.id}`,
                    label: 'View more',
                  }
                : null
            }
          />
        </Section>
      )}

      <EnquiryForm
        type='project'
        projectAddress={`${address_streetNumber} ${address_street} ${address_suburb} ${address_state} ${address_postcode}`}
        headerInfo={{
          image: project.images?.[0].image,
          title: (
            <>
              {address_streetNumber} {address_street}
              <br />
              {address_suburb} {address_state} {address_postcode}
            </>
          ),
          description: (
            <S.ProjectEnquiryDes>
              <strong>{project.status}</strong>{' '}
              {titleCase(project.project_type)}
            </S.ProjectEnquiryDes>
          ),
          id: project.id,
        }}
      />
    </>
  )
}

export default ProjectDetails
