import ReadMoreReadLess from '@global/ReadMoreReadLess'
import * as S from './BlockInfo.styled'

const BlockInfo = ({ title, content }) => {
  return (
    <S.BlockInfo>
      <S.BlockInfoTitle>{title}</S.BlockInfoTitle>
      <ReadMoreReadLess content={content} />
    </S.BlockInfo>
  )
}

export default BlockInfo
