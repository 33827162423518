import styled, { css } from 'styled-components'
import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS, text2XL, textSM, textXS } from '@styles/fonts'

export const ProjectHero = styled.div`
  max-height: 560px;
  display: flex;
  margin-bottom: 40px;

  @media ${DEVICES.mobile} {
    margin-bottom: 20px;
  }
`

export const ProjectHeroInfo = styled.div`
  position: absolute;
  bottom: 26px;
  left: 20px;
  background: ${COLORS.navi};
  color: ${COLORS.white};
  font-family: ${FONTS.medium};
  border-radius: 2px;
  padding: 7px 10px;
  z-index: 99;
  align-items: center;
  display: flex;
  cursor: pointer;

  @media ${DEVICES.mobile} {
    display: flex;
  }
`

export const GalleryCount = styled.span`
  ${textXS};
  align-items: center;
  display: flex;
  text-transform: uppercase;
  letter-spacing: 0.8px;

  svg {
    margin-right: 6px;
  }
`

export const MainPhoto = styled.div`
  width: 100%;
  flex: 0 0 100%;
  position: relative;
  border-radius: 0px 0px 100px 0px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
`

export const Tag = styled.span`
  ${textSM};
  padding: 2px 10px;
  text-transform: uppercase;
  font-family: ${FONTS.bold};
  color: ${COLORS.secondary};
  background: ${COLORS.nature[95]};
  border-radius: 20px;
  letter-spacing: 0.8px;
  position: absolute;
  top: 14px;
  left: 10px;
  z-index: 3;
`

export const ProjectAddress = styled.div`
  ${text2XL};
  color: ${COLORS.secondary};
  text-transform: uppercase;
  margin-bottom: 26px;
`