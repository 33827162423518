import * as S from './ProjectInfo.styled'

const ProjectInfo = ({ items }) => {
  if (!items?.length) return null

  return (
    <S.ProjectInfo>
      <S.ProjectInfoHeading>Project Details</S.ProjectInfoHeading>
      <S.ProjectInfoList>
        {items.map((it, key) => (
          <S.ProjectInfoGroup key={`project-info-${key}`}>
            <S.ProjectInfoLabel>{it.title}: </S.ProjectInfoLabel>
            {it.link ? (
              <a href={it.link} target='_blank'>
                {it.content}
              </a>
            ) : (
              <S.ProjectInfoValue>{it.content}</S.ProjectInfoValue>
            )}
          </S.ProjectInfoGroup>
        ))}
      </S.ProjectInfoList>
    </S.ProjectInfo>
  )
}

export default ProjectInfo
