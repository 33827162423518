import { useState } from 'react'
import IconCamera from '@global/Icons/Camera'
import GalleryMain from '@components/PropertyDetails/Photo/Gallery/GalleryMain'
import FavouriteHeart from '@global/FavouriteHeart'
import ShareButton from '@global/ShareButton'
import useBodyClass from '@hooks/useBodyClass'
import ProjectFeatures from '../ProjectFeatures'
import dynamic from 'next/dynamic'
import * as S from './ProjectHero.styled'

const Gallery = dynamic(() =>
  import('@components/PropertyDetails/Photo/Gallery')
)

const ProjectHero = ({
  images,
  projectType,
  address,
  shareInfo,
  features,
  propertyType,
}) => {
  const [selectedGalleryItem, setSelectedGalleryItem] = useState(null)
  const items = images.map((it) => ({
    href: it.image,
    link_type: 'image',
  }))

  useBodyClass('modal-open', selectedGalleryItem !== null)

  return (
    <>
      <S.ProjectHero>
        <S.MainPhoto>
          <GalleryMain
            fullwidth
            items={items}
            initialSlide={0}
            onImageClick={(key) => setSelectedGalleryItem(key)}
          />
          {items.length > 1 && (
            <S.ProjectHeroInfo>
              <S.GalleryCount onClick={() => setSelectedGalleryItem(0)}>
                <IconCamera />+{items.length - 1} Images
              </S.GalleryCount>
            </S.ProjectHeroInfo>
          )}
          <S.Tag>{projectType}</S.Tag>
          <FavouriteHeart />
        </S.MainPhoto>
      </S.ProjectHero>
      {selectedGalleryItem !== null && (
        <Gallery
          items={items}
          initialSlide={selectedGalleryItem}
          onClose={() => setSelectedGalleryItem(null)}
          propertyInfo={
            <>
              <S.ProjectAddress>{address}</S.ProjectAddress>
              <ProjectFeatures
                features={features}
                propertyType={propertyType}
                gallery
              />
            </>
          }
          shareInfo={
            <ShareButton heading='Share This Project' shareInfo={shareInfo} />
          }
        />
      )}
    </>
  )
}

export default ProjectHero
